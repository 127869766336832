import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Stack } from "@mui/material";

import FeedsGallery from "../explorer/FeedsGallery";
import SectionDevider from "./SectionDevider";
import LoadMore from "../tools/LoadMore";
import { gotoPage } from "../tools/browserHelpers";

import ProjectAPI from "../api/projectApi";
import { useSelector } from "react-redux";

const GlobalFeeds = () => {
  const classes = useStyles();
  const text = useSelector((s) => s.settings.texts.site);

  const [feedsList, setFeedList] = useState([]);
  const [loading, setLoading] = useState(false);

  const calcDateMsg = (ts) => {
    try {
      var date = new Date(ts);
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    } catch (error) {}
    return "";
  };

  const loadFeeds = async (ps) => {
    if (loading || ps < 0) return;

    setLoading(true);
    ProjectAPI.getGlobalFeeds(
      "",
      ps,
      12,
      (m) => {
        setLoading(false);
        if (Array.isArray(m)) {
          if (m.length > 0) {
            var ss = [];
            if (ps > 0) {
              ss = [...feedsList];
            }
            m.forEach((itm) => {
              ss.push({ ...itm, time: calcDateMsg(itm.created_at) });
            });
            setFeedList(ss);
          }
        }
      },
      (x) => {
        setLoading(false);
      }
    );
  };

  const hLoadMore = () => {
    if (!loading) {
      if (feedsList.length < 30) {
        loadFeeds(feedsList.length);
      } else {
        gotoPage("/explore");
      }
    }
  };

  useEffect(() => {
    loadFeeds(0);
  }, []);

  if (Array.isArray(feedsList)) {
    if (feedsList.length > 0) {
      return (
        <div className={classes.root}>
          <Stack spacing={1}>
            <SectionDevider txt={text.feeds} />
            <FeedsGallery feedsData={feedsList} />
            <LoadMore loading={loading} onClick={hLoadMore} />
          </Stack>
        </div>
      );
    }
  }

  return <></>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "block",
    width: "100%",
    margin: 0,
    padding: "15px 8px",
  },
}));

export default GlobalFeeds;
