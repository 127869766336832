import React, { useEffect, useState } from "react";

import { makeStyles } from "@mui/styles";
import { Paper, ButtonBase } from "@mui/material";

import SectionDevider from "./SectionDevider";
import { gotoPage } from "../tools/browserHelpers";

import { DIR_CDN_IMG } from "../data/adresses";

import ProjectAPI from "../api/projectApi";
import { useSelector } from "react-redux";
import HNavContainer from "./HNavContainer";

const SamplesList = () => {
  const classes = useStyles();
  const text = useSelector((s) => s.settings.texts.site);

  const [smList, setSmList] = useState(null);

  useEffect(() => {
    const loadSamplesList = async () => {
      ProjectAPI.getSamples(
        (m) => {
          if (Array.isArray(m)) {
            if (m.length > 0) {
              setSmList(m);
            }
          }
        },
        (x) => {}
      );
    };

    loadSamplesList();
  }, []);

  const fillList = () => {
    return smList.map((itm, i) => (
      <Paper key={i} className={classes.paper}>
        <div className={classes.content}>
          <img
            crossOrigin="anonymous"
            className={classes.media}
            src={DIR_CDN_IMG + itm.img}
          />

          <div className={classes.controls}>
            <ButtonBase
              className={classes.btn}
              onClick={() => {
                gotoPage("/play?id=" + itm.id);
              }}
            >
              <div className={classes.title}>{itm.title}</div>
            </ButtonBase>
          </div>
        </div>
      </Paper>
    ));
  };

  if (Array.isArray(smList)) {
    if (smList.length > 0) {
      return (
        <div className={classes.root}>
          <SectionDevider txt={text.samples} />
          <HNavContainer scollStep={200}>{fillList()}</HNavContainer>
        </div>
      );
    }
  }

  return <></>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "block",
    width: "100%",
    padding: "8px",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
    width: "100%",
    overflow: "hidden",
  },
  paper: {
    padding: "5px",
    margin: "5px",
  },
  content: {
    display: "block",
    position: "relative",
    backgroundColor: "#fff",
    width: "180px",
    height: "130px",
  },
  media: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  controls: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: "rgb(250,250,250,0.6)",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    padding: "5px",
  },
  btn: {
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  title: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textAlign: "right",
    color: "#333",
  },
}));

export default SamplesList;
