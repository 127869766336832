import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

import { useSelector } from "react-redux";

const AboutHome = () => {
  const classes = useStyles();
  const text = useSelector((s) => s.settings.texts.site);

  return (
    <div className={classes.root}>
      <img
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        alt="Schoode"
        src={"/img/schoode-about-bk.jpg"}
      />
      <div className={classes.txt}>
        <Typography textAlign={"center"} variant="subtitle1" color={"#555"}>
          {text.about}
        </Typography>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    position: "relative",
    width: "100%",
    margin: 0,
    padding: 0,
  },
  txt: {
    position: "relative",
    maxWidth: "550px",
    margin: "0 auto",
    padding: "70px 8px",
    backgroundColor: "RGB(255,255,255,0.9)",
  },
}));

export default AboutHome;
