import React, { useState } from "react";
import { Box, Button } from "@mui/material";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import PCreateModal from "../project/CreateProjectModal";
import { useSelector } from "react-redux";

const CreateProjectBtn = () => {
  const text = useSelector((s) => s.settings.texts.site);

  const [openModal, setOpenModal] = useState(false);

  return (
    <Box sx={{ padding: "12px" }}>
      <Button
        variant="contained"
        color="warning"
        startIcon={<AddCircleOutlineIcon />}
        onClick={() => {
          setOpenModal(true);
        }}
      >
        {text.create}
      </Button>

      <PCreateModal
        open={openModal}
        close={() => {
          setOpenModal(false);
        }}
      />
    </Box>
  );
};

export default CreateProjectBtn;
