import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Paper, Box, Stack } from "@mui/material";

import { EditBtn, DeleteBtn } from "../tools/IconBtn";

import SectionDevider from "./SectionDevider";
import CreateProjectBtn from "../project/CreateProjectBtn";
import { checkValidProject } from "../project/ProjectOperations";
import { gotoPage } from "../tools/browserHelpers";

import { ReadLocalProject, DeleteLocalProject } from "../data/LocalStorage";
import { useSelector } from "react-redux";

const LocalList = () => {
  const classes = useStyles();
  const text = useSelector((s) => s.settings.texts.site);

  const [localProject, setlLocalProject] = useState(null);

  useEffect(() => {
    const pjt = ReadLocalProject();

    if (checkValidProject(pjt)) {
      setlLocalProject(pjt);
    }
  }, []);

  const hDeleteProject = () => {
    DeleteLocalProject();
    setlLocalProject(null);
  };

  const showLocalProject = () => {
    if (localProject === null) {
      return <></>;
    }

    var iSrc = "";
    if (localProject.thumb !== null) {
      iSrc = localProject.thumb;
    }

    return (
      <Paper className={classes.paper}>
        <div className={classes.content}>
          <img className={classes.media} alt="" src={iSrc} />

          <div className={classes.controls}>
            <div className={classes.toolbar}>
              <EditBtn
                onClick={() => {
                  gotoPage("/editor");
                }}
              />
              <DeleteBtn
                onClick={() => {
                  hDeleteProject();
                }}
              />
            </div>
            <div className={classes.title}>
              <span>{localProject.title}</span>
            </div>
          </div>
        </div>
      </Paper>
    );
  };

  return (
    <div className={classes.root}>
      <SectionDevider txt={text.myprojects} />
      <Stack spacing={1}>
        <Box
          sx={{
            padding: "0px 20px",
            display: "flex",
            flexDirection: "raw",
            flexWrap: "wrap",
          }}
        >
          <CreateProjectBtn />

          {showLocalProject()}
        </Box>
      </Stack>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "block",
    width: "100%",
    margin: 0,
    padding: "25px 8px",
  },
  paper: {
    width: "150px",
    height: "100px",
    padding: "5px",
    margin: "5px",
  },
  content: {
    display: "block",
    position: "relative",
    backgroundColor: "#fff",
    width: "100%",
    height: "100%",
  },
  media: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  controls: {
    display: "block",
    position: "absolute",
    backgroundColor: "rgb(250,250,250,0.6)",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    padding: "5px",
  },
  toolbar: {
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "0px 5px",
    margin: "5px",
  },
  title: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: "#333",
  },
}));

export default LocalList;
