const txtToJson = (txt) => {
  try {
    const ob = JSON.parse(txt);
    return ob;
  } catch (error) {}

  return null;
};

export const extractProject = (px) => {
  if (px.data) {
    const data = txtToJson(px.data);

    if (data) {
      var ws = [];
      if (px.code) {
        const c = txtToJson(px.code);
        if (c) {
          if (c.workspaces !== undefined) {
            if (Array.isArray(c.workspaces)) {
              ws = c.workspaces;
            }
          }
        }
      }

      const pjt = {
        id: px.id,
        type: px.type,
        title: px.title,
        visible: px.visible,
        share: px.share,
        img: px.img,
        bkColor: data.bkColor,
        scrSize: data.scrSize,
        objects: data.objects,
        tasks: data.tasks,
        workspaces: ws,
      };
      return pjt;
    }
  }

  return null;
};

export const checkValidProject = (pjt) => {
  if (pjt) {
    if (
      pjt.id !== undefined &&
      pjt.type !== undefined &&
      pjt.title !== undefined &&
      pjt.scrSize !== undefined &&
      pjt.objects !== undefined &&
      pjt.tasks !== undefined &&
      pjt.workspaces !== undefined
    ) {
      if (
        Array.isArray(pjt.tasks) &&
        Array.isArray(pjt.objects) &&
        Array.isArray(pjt.workspaces)
      ) {
        return true;
      }
    }
  }

  return false;
};

export const getProjectThumb = (eCtx, scrSize) => {
  if (eCtx) {
    var w = parseInt(scrSize[0]);
    var h = parseInt(scrSize[1]);
    if (w > 800) {
      w = 800;
    }
    if (h > 410) {
      h = 410;
    }

    if (w > 50 && h > 50) {
      try {
        var tmpCanvas = document.createElement("canvas");
        tmpCanvas.width = w;
        tmpCanvas.height = h;
        var tCtx = tmpCanvas.getContext("2d");
        tCtx.drawImage(eCtx, 0, 0, w, h, 0, 0, w, h);
        const bb = tmpCanvas.toDataURL("image/png");
        return bb;
      } catch (error) {}
    }
  }

  return null;
};
