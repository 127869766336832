import axios from "axios";

class SecJsonAPI {
  constructor(url) {
    this.service = axios.create({
      baseURL: url,
      headers: { Accept: "application/json" },
      timeout: 10000,
    });
  }

  async Get(url, onOk, onErr = null) {
    await this.service
      .get(url)
      .then((res) => {
        if (onOk) onOk(res.data);
      })
      .catch((err) => {
        if (onErr) onErr(err.message);
      });
  }

  async Post(url, payload, onOk, onErr = null) {
    const fData = new FormData();

    for (var key in payload) {
      fData.append(key, payload[key]);
    }

    await this.service
      .post(url, fData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        if (onOk) onOk(res.data);
      })
      .catch((err) => {
        if (onErr) onErr(err);
      });
  }
}

export default SecJsonAPI;
