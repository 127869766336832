import React, { useRef, useState } from "react";
import { makeStyles } from "@mui/styles";

import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import { pink } from "@mui/material/colors";

const HNavContainer = ({ children, scollStep }) => {
  const classes = useStyles();

  const ref = useRef(null);

  const scroll = (x) => {
    if (ref.current) {
      ref.current.scrollLeft += x;
    }
  };

  return (
    <div className={classes.root}>
      <div ref={ref} className={classes.container}>
        {children}
      </div>

      <div className={classes.next} style={{ right: 0 }}>
        <div
          className={classes.section}
          onClick={() => {
            scroll(scollStep);
          }}
        >
          <SkipNextIcon sx={{ fontSize: "35px", color: pink[500] }} />
        </div>
      </div>
      <div className={classes.prev} style={{ left: 0 }}>
        <div
          className={classes.section}
          onClick={() => {
            scroll(-scollStep);
          }}
        >
          <SkipPreviousIcon sx={{ fontSize: "35px", color: pink[500] }} />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "block",
    width: "100%",
    margin: 0,
    padding: 0,
  },
  container: {
    position: "relative",
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    padding: "5px 20px",
    overflowX: "scroll",
    overflowY: "hidden",
    scrollBehavior: "smooth",

    msOverflowStyle: "none",
    scrollbarWidth: "none",

    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  section: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    backgroundColor: "#fff",
    opacity: 0.8,

    "&:hover": {
      opacity: 0.95,
    },
  },
  next: {
    position: "absolute",
    top: 0,
    width: "50px",
    height: "100%",
  },
  prev: {
    position: "absolute",
    top: 0,
    width: "50px",
    height: "100%",
  },
}));

export default HNavContainer;
