import SecJsonAPI from "./SecJsonAPI";

const apky = "AIzaSyBfFeoklqpCK0yoVshSUSV7lsVYGSwJ2Bk";

class GglAPI extends SecJsonAPI {
  constructor() {
    super("https://www.googleapis.com/youtube/v3");
  }

  async getPlayList(listID, count, onOk, onErr = null) {
    const url = `/playlistItems?key=${apky}&maxResults=${count}&part=snippet&playlistId=${listID}`;
    await this.Get(url, onOk, onErr);
  }
}

export default new GglAPI();
