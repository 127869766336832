import React from "react";
import Container from "@mui/material/Container";
import { Provider } from "react-redux";
import store from "../coms/data/store";

import Seo from "../coms/global/Seo";
import MainFrame from "../coms/global/MainFrame";
import Layout from "../coms/global/Layout";
import Banner from "../coms/home/banner";
import LocalList from "../coms/home/LocalList";
import SamplesList from "../coms/home/SamplesList";
import UserFeeds from "../coms/home/UserFeeds";
import GlobalFeeds from "../coms/home/GlobalFeeds";
import AboutHome from "../coms/home/AboutHome";
import VideoFeeds from "../coms/home/VideoSection";

const HomePage = () => {
  return (
    <>
      <Seo title="Learn Programming Without Coding" pathname="/" />
      <Provider store={store}>
        <MainFrame>
          <Layout>
            <Banner />
            <Container
              maxWidth="lg"
              sx={{
                backgroundColor: "#FAFAFA",
                padding: "10px 0px",
              }}
            >
              <AboutHome />
              <LocalList />
              <UserFeeds />
              <SamplesList />
              <GlobalFeeds />
            </Container>
          </Layout>
        </MainFrame>
      </Provider>
    </>
  );
};

export default HomePage;
