import React from "react";

import { Typography } from "@mui/material";

const SectionDevider = ({ txt }) => {
  return (
    <div
      style={{
        width: "100%",
        padding: "10px",
        margin: "10px 0px",
        borderTop: "1px solid #aaa",
      }}
    >
      <Typography margin={"0px 10px"} variant="h6">
        {txt}
      </Typography>
    </div>
  );
};

export default SectionDevider;
