import React from "react";

import { useSelector } from "react-redux";

const Banner = () => {
  const { name } = useSelector((s) => s.settings.lang);

  return (
    <div
      style={{
        display: "block",
        position: "relative",
        width: "100%",
        height: "230px",
      }}
    >
      <img
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
        alt="Schoode"
        src={
          name == "ar"
            ? "/img/schoode-banner-ar.gif"
            : "/img/schoode-banner-en.gif"
        }
      />
    </div>
  );
};

export default Banner;
