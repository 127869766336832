import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Paper, Stack } from "@mui/material";

import SectionDevider from "./SectionDevider";
import HNavContainer from "./HNavContainer";
import { gotoPage } from "../tools/browserHelpers";

import gglApi from "../api/gglApi";
import { useSelector } from "react-redux";

const VideoFeeds = () => {
  const classes = useStyles();
  const text = useSelector((s) => s.settings.texts.site);
  const lang = useSelector((s) => s.settings.lang);

  const [feedsList, setFeedList] = useState([]);

  useEffect(() => {
    var listID = "PLMEOnz7Q9e93e8vJ388UCTnXYw8btL23e";
    if (lang.name.startsWith("ar")) {
      listID = "PLMEOnz7Q9e92yFmQgjRAIDTVv7p6b04Pc";
    }

    gglApi.getPlayList(
      listID,
      10,
      (m) => {
        if (m.items) {
          const ss = [];
          m.items.forEach((itm) => {
            const snp = itm.snippet;
            ss.push({
              title: snp.title,
              img: snp.thumbnails.medium ? snp.thumbnails.medium.url : "",
              url: "https://www.youtube.com/watch?v=" + snp.resourceId.videoId,
            });
          });
          setFeedList(ss);
        }
      },
      (x) => {}
    );
  }, []);

  const showVideoItem = (itm) => {
    return (
      <Paper className={classes.paper}>
        <div
          className={classes.content}
          onClick={() => {
            gotoPage(itm.url, true);
          }}
        >
          <img alt={""} className={classes.media} src={itm.img} />
          <div className={classes.title}>
            <span>{itm.title || ""}</span>
          </div>
        </div>
      </Paper>
    );
  };

  const fillList = () => {
    return feedsList.map((itm) => showVideoItem(itm));
  };

  if (feedsList.length > 0)
    return (
      <div className={classes.root}>
        <Stack spacing={1}>
          <SectionDevider txt={text.tut_videos} />
          <HNavContainer scollStep={210}>{fillList()}</HNavContainer>
        </Stack>
      </div>
    );

  return <></>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "block",
    width: "100%",
    margin: 0,
    padding: "10px 5px",
  },
  paper: {
    display: "block",
    position: "relative",
    flexShrink: 0,
    width: "200px",
    padding: "5px",
    margin: "5px 10px",
  },
  content: {
    display: "block",
    position: "relative",
    cursor: "pointer",
    backgroundColor: "#fff",
    width: "100%",
    height: "100%",
  },
  media: {
    width: "100%",
    height: "100px",
    objectFit: "cover",
  },

  title: {
    color: "#333",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: theme.spacing(0, 1),
  },
}));

export default VideoFeeds;

const fff = {
  bkColor: "#fbfbfb",
  scrSize: [570, 350],
  objects: [
    {
      id: "IM_2e1814cef8011",
      isTask: false,
      locked: true,
      type: "OBTYPE_IMAGE",
      category: "image",
      name: "u10_5441654853171",
      thumb: "https://cdn.schoode.com/img/2022/06/tb_u10_5441654853171.png",
      img: "https://cdn.schoode.com/img/2022/06/u10_5441654853171.png",
      params: { w: 600, h: 524, fcnt: 1 },
      props: {
        visible: true,
        flip: false,
        x: 275,
        y: 130,
        ang: 0,
        zoom: 100,
        op: 100,
        msg: "",
        speed: 1,
        fn: 0,
        tc: "#333",
        fc: null,
        lc: null,
      },
    },
    {
      id: "u10_8121654609076_91654609079556",
      isTask: false,
      locked: false,
      type: "OBTYPE_IMAGE",
      category: "image",
      name: "u10_8121654609076",
      thumb: "https://cdn.schoode.com/img/2022/06/tb_u10_8121654609076.png",
      img: "https://cdn.schoode.com/img/2022/06/u10_8121654609076.png",
      params: { w: 600, h: 524, fcnt: 1 },
      props: {
        visible: true,
        flip: false,
        x: 661,
        y: 147,
        ang: 0,
        zoom: 100,
        op: 100,
        msg: "",
        speed: 1,
        fn: 0,
        tc: "#333",
        fc: null,
        lc: null,
      },
    },
    {
      id: "g320i20png_11641340125283",
      isTask: true,
      locked: false,
      type: "OBTYPE_IMAGE",
      category: "image",
      name: "g320i20.png",
      thumb: "/img/obj/g320/ti20.png",
      img: "/img/obj/g320/i20.png",
      params: { fcnt: 1, w: 222, h: 250 },
      props: {
        visible: true,
        x: 112,
        y: 264,
        zoom: 45,
        ang: 0,
        op: 100,
        flip: false,
        msg: "",
        speed: 1,
        fn: 0,
        tc: "#333",
        fc: null,
        lc: null,
      },
    },
    {
      id: "g320i11png_01641340219129",
      isTask: true,
      locked: false,
      type: "OBTYPE_IMAGE",
      category: "image",
      name: "g320i11.png",
      thumb: "/img/obj/g320/ti11.png",
      img: "/img/obj/g320/i11.png",
      params: { fcnt: 1, w: 250, h: 148 },
      props: {
        visible: true,
        x: 103,
        y: 86,
        zoom: 50,
        ang: 0,
        op: 100,
        flip: false,
        msg: "",
        speed: 1,
        fn: 0,
        tc: "#333",
        fc: null,
        lc: null,
      },
    },
    {
      id: "g320i15png_71641340362597",
      isTask: true,
      locked: false,
      type: "OBTYPE_IMAGE",
      category: "image",
      name: "g320i15.png",
      thumb: "/img/obj/g320/ti15.png",
      img: "/img/obj/g320/i15.png",
      params: { fcnt: 1, w: 250, h: 218 },
      props: {
        visible: true,
        x: 268,
        y: 216,
        zoom: 25,
        ang: 0,
        op: 100,
        flip: false,
        msg: "",
        speed: 1,
        fn: 0,
        tc: "#333",
        fc: null,
        lc: null,
      },
    },
    {
      id: "EXT_71641340411979",
      isTask: true,
      locked: false,
      type: "OBTYPE_IMAGE",
      category: "image",
      name: "g320i15.png",
      thumb: "/img/obj/g320/ti15.png",
      img: "/img/obj/g320/i15.png",
      params: { fcnt: 1, w: 250, h: 218 },
      props: {
        visible: true,
        x: 118,
        y: 171,
        zoom: 19,
        ang: 0,
        op: 100,
        flip: false,
        msg: "",
        speed: 1,
        fn: 0,
        tc: "#333",
        fc: null,
        lc: null,
      },
    },
    {
      id: "EXT_91641340412614",
      isTask: true,
      locked: false,
      type: "OBTYPE_IMAGE",
      category: "image",
      name: "g320i15.png",
      thumb: "/img/obj/g320/ti15.png",
      img: "/img/obj/g320/i15.png",
      params: { fcnt: 1, w: 250, h: 218 },
      props: {
        visible: true,
        x: 358,
        y: 164,
        zoom: 27,
        ang: 0,
        op: 100,
        flip: false,
        msg: "",
        speed: 1,
        fn: 0,
        tc: "#333",
        fc: null,
        lc: null,
      },
    },
  ],
  tasks: [
    {
      id: "g320i11png_01641340219129",
      name: "start",
      stmt: [
        { type: "NA" },
        {
          type: "group",
          name: "loop",
          stp: 0,
          param: [0, 999999, 0],
          stmt: [
            { type: "NA" },
            {
              type: "group",
              name: "loop",
              stp: 0,
              param: [0, 999999, 0],
              stmt: [
                { type: "NA" },
                {
                  type: "stmt",
                  cat: "action",
                  name: "moveForward",
                  param: ["m", "d", 0.5, 0],
                },
                {
                  type: "stmt",
                  cat: "calc",
                  name: "mathCmp",
                  param: ["#V19234", "<<", "p", "x", "d", 500],
                },
                {
                  type: "stmt",
                  cat: "logic",
                  name: "logicIf",
                  param: [2, 0, "v", "#V19234"],
                },
                { type: "stmt", cat: "break", name: "break", param: [0, 0, 0] },
              ],
            },
            { type: "stmt", cat: "action", name: "tglFlip", param: [0, 0, 0] },
            {
              type: "group",
              name: "loop",
              stp: 0,
              param: [0, 999999, 0],
              stmt: [
                { type: "NA" },
                {
                  type: "stmt",
                  cat: "action",
                  name: "moveForward",
                  param: ["m", "d", 0.4, 0],
                },
                {
                  type: "stmt",
                  cat: "calc",
                  name: "mathCmp",
                  param: ["#V14882", ">>", "p", "x", "d", 100],
                },
                {
                  type: "stmt",
                  cat: "logic",
                  name: "logicIf",
                  param: [2, 0, "v", "#V14882"],
                },
                { type: "stmt", cat: "break", name: "break", param: [0, 0, 0] },
              ],
            },
            { type: "stmt", cat: "action", name: "tglFlip", param: [0, 0, 0] },
          ],
        },
      ],
    },
    {
      id: "g320i15png_71641340362597",
      name: "start",
      stmt: [
        { type: "NA" },
        {
          type: "group",
          name: "loop",
          stp: 0,
          param: [0, 999999, 0],
          stmt: [
            { type: "NA" },
            {
              type: "group",
              name: "loop",
              stp: 0,
              param: [0, 999999, 0],
              stmt: [
                { type: "NA" },
                {
                  type: "stmt",
                  cat: "action",
                  name: "moveForward",
                  param: ["m", "d", 0.2, 0],
                },
                {
                  type: "stmt",
                  cat: "calc",
                  name: "mathCmp",
                  param: ["#V15847", "<<", "p", "x", "d", 450],
                },
                {
                  type: "stmt",
                  cat: "logic",
                  name: "logicIf",
                  param: [2, 0, "v", "#V15847"],
                },
                { type: "stmt", cat: "break", name: "break", param: [0, 0, 0] },
              ],
            },
            { type: "stmt", cat: "action", name: "tglFlip", param: [0, 0, 0] },
            {
              type: "group",
              name: "loop",
              stp: 0,
              param: [0, 999999, 0],
              stmt: [
                { type: "NA" },
                {
                  type: "stmt",
                  cat: "action",
                  name: "moveForward",
                  param: ["m", "d", 0.2, 0],
                },
                {
                  type: "stmt",
                  cat: "calc",
                  name: "mathCmp",
                  param: ["#V15561", ">>", "p", "x", "d", 200],
                },
                {
                  type: "stmt",
                  cat: "logic",
                  name: "logicIf",
                  param: [2, 0, "v", "#V15561"],
                },
                { type: "stmt", cat: "break", name: "break", param: [0, 0, 0] },
              ],
            },
            { type: "stmt", cat: "action", name: "tglFlip", param: [0, 0, 0] },
          ],
        },
      ],
    },
    {
      id: "EXT_71641340411979",
      name: "start",
      stmt: [
        { type: "NA" },
        {
          type: "group",
          name: "loop",
          stp: 0,
          param: [0, 999999, 0],
          stmt: [
            { type: "NA" },
            {
              type: "group",
              name: "loop",
              stp: 0,
              param: [0, 999999, 0],
              stmt: [
                { type: "NA" },
                {
                  type: "stmt",
                  cat: "action",
                  name: "moveForward",
                  param: ["m", "d", 0.1, 0],
                },
                {
                  type: "stmt",
                  cat: "calc",
                  name: "mathCmp",
                  param: ["#V16029", "<<", "p", "x", "d", 450],
                },
                {
                  type: "stmt",
                  cat: "logic",
                  name: "logicIf",
                  param: [2, 0, "v", "#V16029"],
                },
                { type: "stmt", cat: "break", name: "break", param: [0, 0, 0] },
              ],
            },
            { type: "stmt", cat: "action", name: "tglFlip", param: [0, 0, 0] },
            {
              type: "group",
              name: "loop",
              stp: 0,
              param: [0, 999999, 0],
              stmt: [
                { type: "NA" },
                {
                  type: "stmt",
                  cat: "action",
                  name: "moveForward",
                  param: ["m", "d", 0.1, 0],
                },
                {
                  type: "stmt",
                  cat: "calc",
                  name: "mathCmp",
                  param: ["#V11484", ">>", "p", "x", "d", 200],
                },
                {
                  type: "stmt",
                  cat: "logic",
                  name: "logicIf",
                  param: [2, 0, "v", "#V11484"],
                },
                { type: "stmt", cat: "break", name: "break", param: [0, 0, 0] },
              ],
            },
            { type: "stmt", cat: "action", name: "tglFlip", param: [0, 0, 0] },
          ],
        },
      ],
    },
    {
      id: "EXT_91641340412614",
      name: "start",
      stmt: [
        { type: "NA" },
        {
          type: "group",
          name: "loop",
          stp: 0,
          param: [0, 999999, 0],
          stmt: [
            { type: "NA" },
            {
              type: "group",
              name: "loop",
              stp: 0,
              param: [0, 999999, 0],
              stmt: [
                { type: "NA" },
                {
                  type: "stmt",
                  cat: "action",
                  name: "moveForward",
                  param: ["m", "d", 0.2, 0],
                },
                {
                  type: "stmt",
                  cat: "calc",
                  name: "mathCmp",
                  param: ["#V11827", "<<", "p", "x", "d", 450],
                },
                {
                  type: "stmt",
                  cat: "logic",
                  name: "logicIf",
                  param: [2, 0, "v", "#V11827"],
                },
                { type: "stmt", cat: "break", name: "break", param: [0, 0, 0] },
              ],
            },
            { type: "stmt", cat: "action", name: "tglFlip", param: [0, 0, 0] },
            {
              type: "group",
              name: "loop",
              stp: 0,
              param: [0, 999999, 0],
              stmt: [
                { type: "NA" },
                {
                  type: "stmt",
                  cat: "action",
                  name: "moveForward",
                  param: ["m", "d", 0.1, 0],
                },
                {
                  type: "stmt",
                  cat: "calc",
                  name: "mathCmp",
                  param: ["#V15161", ">>", "p", "x", "d", 200],
                },
                {
                  type: "stmt",
                  cat: "logic",
                  name: "logicIf",
                  param: [2, 0, "v", "#V15161"],
                },
                { type: "stmt", cat: "break", name: "break", param: [0, 0, 0] },
              ],
            },
            { type: "stmt", cat: "action", name: "tglFlip", param: [0, 0, 0] },
          ],
        },
      ],
    },
    {
      id: "g320i20png_11641340125283",
      name: "start",
      stmt: [
        { type: "NA" },
        {
          type: "group",
          name: "loop",
          stp: 0,
          param: [0, 999999, 0],
          stmt: [
            { type: "NA" },
            {
              type: "group",
              name: "loop",
              stp: 0,
              param: [0, 50, 0],
              stmt: [
                { type: "NA" },
                {
                  type: "stmt",
                  cat: "action",
                  name: "rotate",
                  param: ["d", 0.3, 0],
                },
              ],
            },
            {
              type: "group",
              name: "loop",
              stp: 0,
              param: [0, 50, 0],
              stmt: [
                { type: "NA" },
                {
                  type: "stmt",
                  cat: "action",
                  name: "rotate",
                  param: ["d", 359.7, 0],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

const ffp = {
  id: "u10_8121654609076_91654609079556",
  isTask: false,
  locked: false,
  type: "OBTYPE_IMAGE",
  category: "image",
  name: "u10_8121654609076",
  thumb: "https://cdn.schoode.com/img/2022/06/tb_u10_8121654609076.png",
  img: "https://cdn.schoode.com/img/2022/06/u10_8121654609076.png",
  params: { w: 600, h: 524, fcnt: 1 },
  props: {
    visible: true,
    flip: false,
    x: 661,
    y: 147,
    ang: 0,
    zoom: 100,
    op: 100,
    msg: "",
    speed: 1,
    fn: 0,
    tc: "#333",
    fc: null,
    lc: null,
  },
};
