import React, { useEffect, useState } from "react";

import { makeStyles } from "@mui/styles";
import { Paper, Button, ButtonBase } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import { EditBtn, DeleteBtn } from "../tools/IconBtn";
import { gotoPage } from "../tools/browserHelpers";

import { DIR_CDN_IMG } from "../data/adresses";
import ProjectAPI from "../api/projectApi";
import { useSelector } from "react-redux";

const UserFeeds = () => {
  const classes = useStyles();
  const user = useSelector((s) => s.settings.user);
  const text = useSelector((s) => s.settings.texts.site);

  const [buzyDel, setBuzyDel] = useState(false);
  const [feedsList, setFeedList] = useState([]);

  useEffect(() => {
    const loadFeedsList = async () => {
      ProjectAPI.getUserFeeds(user, user.id, 0, 3, (m) => {
        if (Array.isArray(m)) {
          if (m.length > 0) {
            setFeedList(m);
          }
        }
      });
    };

    if (user) {
      loadFeedsList();
    }
  }, []);

  const hDeletePrject = (id) => {
    if (user) {
      setBuzyDel(true);
      ProjectAPI.deleteProject(
        user,
        id,
        (m) => {
          if (m > 0) {
            const s = [];
            feedsList.forEach((itm) => {
              if (itm.id !== id) s.push(itm);
            });
            setFeedList(s);
          }
          setBuzyDel(false);
        },
        (x) => {
          setBuzyDel(false);
        }
      );
    }
  };

  const fillFeedsList = () => {
    const lst = [];
    for (var i = 0; i < feedsList.length && i < 4; i++) {
      const itm = feedsList[i];
      lst.push(
        <Paper key={i} className={classes.paper}>
          <img
            crossOrigin="anonymous"
            className={classes.media}
            src={DIR_CDN_IMG + itm.img}
          />

          <div className={classes.toolbar}>
            <EditBtn
              onClick={() => {
                gotoPage("/editor?id=" + itm.id);
              }}
            />
            <div className={classes.title}>{itm.title}</div>
            <DeleteBtn
              buzy={buzyDel}
              onClick={() => {
                hDeletePrject(itm.id);
              }}
            />
          </div>

          <div className={classes.mainBtn}>
            <ButtonBase
              onClick={() => {
                gotoPage("/play?id=" + itm.id);
              }}
            >
              <PlayArrowIcon style={{ color: "#0d0", fontSize: 40 }} />
            </ButtonBase>
          </div>
        </Paper>
      );
    }

    return lst;
  };

  if (Array.isArray(feedsList)) {
    if (feedsList.length > 0) {
      return (
        <div className={classes.root}>
          <div className={classes.xraw}>
            {fillFeedsList()}

            <Paper className={classes.moreBtn}>
              <Button
                variant="outlined"
                color="success"
                size="large"
                onClick={() => {
                  gotoPage("/profile");
                }}
              >
                {text.more}
              </Button>
            </Paper>
          </div>
        </div>
      );
    }
  }

  return <></>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "block",
    width: "100%",
    margin: 0,
    padding: "8px",
  },
  xraw: {
    position: "relative",
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    padding: "8px ",
    overflowX: "scroll",
    overflowY: "hidden",

    "&::-webkit-scrollbar": {
      height: "8px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 4px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 4px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ddd",
      borderRadius: "3px",
    },
  },
  paper: {
    display: "block",
    position: "relative",
    flexShrink: 0,
    width: "180px",
    height: "130px",
    padding: "5px",
    margin: "5px",
  },
  media: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 0.75,
    objectFit: "cover",
  },
  toolbar: {
    position: "relative",
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    borderRadius: "5px",
    backgroundColor: "rgb(200,200,200,.6)",
    width: "100%",
    padding: "5px",
  },
  title: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textAlign: "right",
    color: "#333",
  },
  mainBtn: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    padding: "10px",
  },
  moreBtn: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "130px",
    padding: "10px",
  },
}));

export default UserFeeds;
